import { useRef } from "react";
import "../styles/Products.scss";
import "../styles/ProductsAdaptive.scss";
import "swiper/css";
import "swiper/css/navigation";

const Products = ({ openModal }: any) => {
  const accordionItemRefs = useRef<HTMLDivElement[]>([]);
  const toggleAccordion = (index: number) => {
    if (accordionItemRefs.current[index]) {
      accordionItemRefs.current[index].classList.toggle("open");
    }
  };
  return (
    <div className="Products" id="prods">
      <div className="Products__container">
        <div className="Products_accordeon">
          <div className="prod_accordion">
            {prods.map((item, index) => (
              <div
                className="prod_accordion-item open"
                ref={(el) => (accordionItemRefs.current[item.id] = el as HTMLDivElement)}
                key={index}
              >
                <div className="accordion-item-header" onClick={() => toggleAccordion(item.id)}>
                  <span className="accordion-item-header-title">{item.title}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-chevron-down accordion-item-header-icon"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </div>
                <div className="accordion-item-description-wrapper">
                  <div className="accordion-item-description">
                    {item.prod_list.map((item, i) => (
                      <div
                        className={item.label === "true" ? "products_item_hit" : "products_item"}
                        key={i}
                        onClick={openModal}
                      >
                        <img src={process.env.PUBLIC_URL + item.img} alt="" />
                        {item.label === "true" && <div className="products_item_label">ХІТ Продажів</div>}
                        <div className="products_item_title">
                          <p>{item.title}</p>
                        </div>
                        <div className="products_item_params">
                          <div className="products_item_param">
                            <p>{item.text1}</p>
                          </div>
                          <div className="products_item_param">
                            <p>{item.text2}</p>
                          </div>
                          <div className="products_item_param">
                            <p>{item.text3}</p>
                          </div>
                        </div>
                        <p className="products_item_price">
                          Ціна: <p style={{ color: "#d0e774", margin: "10px 0px" }}>{item.price}</p>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;

const prods = [
  {
    id: 1,
    title: "Настінні",
    prod_list: [
      {
        id: 1,
        img: "pr3.webp",
        title: "Акумуляторна батарея LPBF48200-H",
        text1: "10 кВт-год",
        text2: "200Ah",
        text3: "51.2V",
        price: "1850$",
        label: "true",
      },
      {
        id: 2,
        img: "pr1.webp",
        title: "Акумуляторна батарея LUX-E-48100LG04",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "1130$",
        label: "false",
      },
      {
        id: 3,
        img: "pr2.webp",
        title: "Акумуляторна батарея BOS-GM5.1 Deye",
        text1: "5.12 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "1180$",
        label: "false",
      },
      {
        id: 4,
        img: "pr4.webp",
        title: "Акумуляторна батарея LPBA48200",
        text1: "10 кВт-год",
        text2: "200Ah",
        text3: "51.2V",
        price: "2500$",
        label: "false",
      },
    ],
  },
  {
    id: 2,
    title: "Стаціонарні на колесиках",
    prod_list: [
      {
        id: 1,
        img: "pr5.webp",
        title: "Акумуляторна батарея LUX-E-48250LG03",
        text1: "12.5 кВт-год",
        text2: "250Ah",
        text3: "51.2V",
        price: "2660$",
        label: "false",
      },
      {
        id: 2,
        img: "pr6.webp",
        title: "Акумуляторна батарея LPBF48300",
        text1: "15 кВт-год",
        text2: "300Ah",
        text3: "51.2V",
        price: "3160$",
        label: "false",
      },
      {
        id: 3,
        img: "pr7.webp",
        title: "Акумуляторна батарея LUX-Y-48300LG01",
        text1: "15 кВт-год",
        text2: "300Ah",
        text3: "51.2V",
        price: "3260$",
        label: "false",
      },
      {
        id: 4,
        img: "pr8.webp",
        title: "Акумуляторна батарея LPBF48350",
        text1: "17.5 кВт-год",
        text2: "350Ah",
        text3: "51.2V",
        price: "3550$",
        label: "false",
      },
      {
        id: 5,
        img: "pr9.webp",
        title: "Акумуляторна батарея LPBF48500",
        text1: "25.5 кВт-год",
        text2: "500Ah",
        text3: "51.2V",
        price: "4750$",
        label: "false",
      },
    ],
  },
  {
    id: 3,
    title: "Наборні",
    prod_list: [
      {
        id: 1,
        img: "pr10.webp",
        title: "Акумуляторна батарея FLS 48100-C",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "1150$",
        label: "false",
      },
      {
        id: 2,
        img: "pr11.webp",
        title: "Акумуляторна батарея LUX-X-48100LMG01",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "1100$",
        label: "false",
      },
      {
        id: 4,
        img: "pr26.webp",
        title: "Акумуляторна батарея SE-G5.1 Pro-B Deye",
        text1: "5,12 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "1330$",
        label: "false",
      },
      {
        id: 3,
        img: "pr22.webp",
        title: "Високовольтна акумуляторна батарея BOS-GM5.1 Deye",
        text1: "5,12 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "1400$",
        label: "false",
      },
    ],
  },
  {
    id: 4,
    title: "Інвертори",
    prod_list: [
      {
        id: 4,
        img: "pr23.webp",
        title: "Гібридний інвертор IVEM5048-LV",
        text1: "5 кВт",
        text2: "1 фазa",
        text3: "1 MMPT",
        price: "650$",
        label: "true",
      },
      {
        id: 5,
        img: "pr23.webp",
        title: "Гібридний інвертор IVEM8048-LV",
        text1: "8 кВт",
        text2: "1 фазa",
        text3: "1 MMPT",
        price: "950$",
        label: "true",
      },
      {
        id: 1,
        img: "pr12.webp",
        title: "Гібридний інвертор T-REX-5KLP1G01",
        text1: "5 кВт",
        text2: "1 фаза",
        text3: "1 MMPT",
        price: "1190$",
        label: "false",
      },
      {
        id: 2,
        img: "pr13.webp",
        title: "Гібридний інвертор T-REX-10KLP3G01",
        text1: "10 кВт",
        text2: "3 фази",
        text3: "1 MMPT",
        price: "2040$",
        label: "false",
      },
      {
        id: 3,
        img: "pr14.webp",
        title: "Гібридний інвертор Apollo-5KLP1G01-MX",
        text1: "5 кВт",
        text2: "1 фазa",
        text3: "1 MMPT",
        price: "1500$",
        label: "false",
      },
      {
        id: 6,
        img: "pr24.webp",
        title: "Гібридний інвертор SUN-12K-SG04LP3-EU",
        text1: "12 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "2800$",
        label: "false",
      },
      {
        id: 7,
        img: "pr25.webp",
        title: "Гібридний інвертор SUN-50K-SG01HP3-EU-BM4",
        text1: "50 кВт",
        text2: "3 фази",
        text3: "4 MMPT",
        price: "7200$",
        label: "false",
      },
    ],
  },
  {
    id: 5,
    title: "Сонячні панелі",
    prod_list: [
      {
        id: 17,
        img: "pr17.webp",
        title: "Фотоелектричний модуль DHN-60X16/DG",
        text1: "480W",
        text2: "26 кг",
        text3: "30 Ампер",
        price: "102$",
        label: "true",
      },
      {
        id: 15,
        img: "pr15.webp",
        title: "Фотоелектричний модуль DHN-78X16/DG",
        text1: "630W",
        text2: "34.2 кг",
        text3: "25 Ампер",
        price: "160$",
        label: "false",
      },
      {
        id: 16,
        img: "pr15.webp",
        title: "Фотоелектричний модуль DHN-72X16/DG",
        text1: "585W",
        text2: "30.5 кг",
        text3: "25 Ампер",
        price: "155$",
        label: "false",
      },
      {
        id: 16,
        img: "pr15.webp",
        title: "Фотоелектричний модуль DHM-72X10",
        text1: "550W",
        text2: "28 кг",
        text3: "25 Ампер",
        price: "135$",
        label: "false",
      },
      {
        id: 18,
        img: "pr18.webp",
        title: "Фотоелектричний модуль DHN-54X16/FS",
        text1: "440W",
        text2: "20.1 кг",
        text3: "25 Ампер",
        price: "97$",
        label: "false",
      },
      {
        id: 18,
        img: "pr27.webp",
        title: "Фотоелектричний модуль DHN-54X16/FS",
        text1: "435W",
        text2: "16.6 кг",
        text3: "25 Ампер",
        price: "98$",
        label: "false",
      },
    ],
  },
  {
    id: 6,
    title: "Комутація",
    prod_list: [
      {
        id: 19,
        img: "pr20.webp",
        title: "Кабель для сон. батарей KBE Solar DB+",
        text1: "500 м",
        text2: "IEC 62930",
        text3: "2 PFG",
        price: "640$",
        label: "false",
      },
      {
        id: 20,
        img: "pr21.webp",
        title: "Кабель для сон. батарей KBE Solar DB+",
        text1: "500 м",
        text2: "IEC 62930",
        text3: "2 PFG",
        price: "640$",
        label: "false",
      },
    ],
  },
];
